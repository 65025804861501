import React, { Component } from "react";
import Sketch from "react-p5";

let img;

export default class App extends Component {

  preload = (p5) => {
    img = p5.loadImage("/love.jpg");  // REMEMBER: Texture needs to live in /public
  }

  setup = (p5) => {
    p5.createCanvas(window.innerWidth, window.innerHeight, p5.WEBGL);
    p5.strokeWeight(0);
  };

  draw = (p5) => {
    p5.background(500);
    p5.orbitControl();
    p5.texture(img);
    let axis = [1, 1, 1];
    p5.rotate(p5.millis() / 1000 * 3.14 / 2, axis);
    p5.box(250);
  };

  render() {
    return <Sketch preload={this.preload} setup={this.setup} draw={this.draw} />;
  }
}